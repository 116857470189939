<template>
  <main class="company-profile">
    <div class="about-banner"></div>
    <div class="none-about"></div>
    <div class="about-opacity"></div>
    <div class="about-main clearfix">
      <div class="news-column">
        <div class="column-title">
          关于我们
        </div>
        <div class="children-list">
          <div class="item list-active"><router-link to="/about/company">公司介绍</router-link></div>
          <div class="item"><router-link to="/about/presence">员工风采</router-link></div>
        </div>
      </div>

      <div class="company-content">
        <div class="headline-box">
          <div class="title-lef">
            <div class="title-cn">公司介绍</div>
            <div class="title-en">COMPANY PROFILE</div>
          </div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>公司介绍</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="company-article">
          <div class="introduce-box" v-html="aboutUs.content"></div>
          <div class="about-us-map">
            <div id="allmap"></div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'company-profile',
    created() {

    },
    computed: {
      ...mapState({
        aboutUs: state => state.user.aboutUs,
        orgInfo: state => state.user.orgInfo
      })
    },
    mounted() {
      this.loadingMap()
    },
    methods: {
      loadingMap() {
        // 百度地图API功能
        var map = new BMapGL.Map("allmap");
        var point = new BMapGL.Point(this.orgInfo.longitude||123.477818 , this.orgInfo.latitude || 41.836021);
        map.centerAndZoom(point, 19);

        var marker = new BMapGL.Marker(point);  // 创建标注
        map.addOverlay(marker);              // 将标注添加到地图中
        var opts = {
          width : 200,     // 信息窗口宽度
          height: 100,     // 信息窗口高度
          title : this.orgInfo.name || "故宫博物院" , // 信息窗口标题
          message: `这里是${this.orgInfo.name}`
        }
        var infoWindow = new BMapGL.InfoWindow(`地址：${this.orgInfo.address}`, opts);  // 创建信息窗口对象
        marker.addEventListener("click", function(){
          map.openInfoWindow(infoWindow, point); //开启信息窗口
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
/deep/ .el-breadcrumb {
  margin-right: 48px;
  position: absolute;
  right: 48px;
  top: 36px;
  .el-breadcrumb__inner {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
}
@import "./about-us.scss";
</style>
